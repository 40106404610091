import { Button } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

type Props = {
  isLogin: any;
  eventJoinList: any;
  cancelUser: any;
};

const MenuJoin: React.VFC<Props> = ({
  isLogin,
  eventJoinList,
  cancelUser,
}: Props) => {
  const navigate = useNavigate();
  return (
    <div>
      <u>🔳 参加予定の一覧</u>
      <br />
      {isLogin ? (
        <>
          <table>
            <tbody>
              {eventJoinList.length === 0 ? (
                <tr>
                  <td>イベントに参加していません。</td>
                </tr>
              ) : (
                <>
                  {eventJoinList.map((item: any, index: any) => (
                    <tr>
                      <td>{item.title}</td>
                      <td>
                        <Button
                          style={{
                            display: "inline-block",
                            padding: "10px 10px",
                            fontSize: "18px",
                            color: "#fff",
                            backgroundColor: "#3498db",
                            textDecoration: "none",
                            borderRadius: "5px",
                            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                            transition: "background-color 0.3s ease",
                          }}
                          onClick={() => {
                            cancelUser(item.id, item.title);
                          }}
                        >
                          不参加
                        </Button>
                      </td>
                      <td>
                        <Button
                          style={{
                            display: "inline-block",
                            padding: "10px 10px",
                            fontSize: "18px",
                            color: "#fff",
                            backgroundColor: "#3498db",
                            textDecoration: "none",
                            borderRadius: "5px",
                            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                            transition: "background-color 0.3s ease",
                          }}
                          onClick={() => {
                            navigate("/Event/" + item.id);
                          }}
                        >
                          詳細
                        </Button>
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
          <br />
          <u>🔳 過去の参加一覧</u>
          <br />
          過去参加イベントがありません
          <br />
        </>
      ) : (
        <>
          <br />
          まずは
          <Button
            style={{
              display: "inline-block",
              padding: "10px 10px",
              fontSize: "18px",
              color: "#fff",
              backgroundColor: "#CC9900",
              textDecoration: "none",
              borderRadius: "5px",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              transition: "background-color 0.3s ease",
            }}
            onClick={() => {
              navigate("/Signin/");
            }}
          >
            ログイン
          </Button>
          しましょう
        </>
      )}
    </div>
  );
};

export default MenuJoin;
