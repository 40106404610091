import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import ReactLoading from "react-loading";

export default function LodingModal(props) {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={props.open}
      closeAfterTransition
    >
      <Fade in={props.open} style={{ outline: "none" }}>
        <div align="center" style={{ marginTop: "35vh", border: 0 }}>
          <ReactLoading type="bars" />
        </div>
      </Fade>
    </Modal>
  );
}
