import { otherApp } from "./firebase";
import {
  doc,
  collection,
  getFirestore,
  runTransaction,
  query,
  getDocs,
  getDoc,
  orderBy,
  limit,
  addDoc,
  setDoc,
  deleteDoc,
  updateDoc,
  documentId,
  where,
} from "firebase/firestore";

export async function setUserPublic(userId: any, data: any): Promise<any> {
  const db = getFirestore(otherApp);
  let q = doc(db, "userpublic", userId);
  const docRef = await setDoc(q, data);
  return docRef;
}

export async function updateUserPublic(userId: any, data: any): Promise<any> {
  const db = getFirestore(otherApp);
  let q = doc(db, "userpublic", userId);
  const docRef = await updateDoc(q, data);
  return docRef;
}

export async function updateOrSetUserPublic(userId: string, data: any): Promise<void> {
  const db = getFirestore(otherApp);
  const docRef = doc(db, "userpublic", userId);

  try {
    // ドキュメントが存在するかどうか確認
    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) {
      // ドキュメントが存在する場合、updateDocで更新
      await updateDoc(docRef, data);
      console.log("Document updated successfully.");
    } else {
      // ドキュメントが存在しない場合、setDocで作成
      await setDoc(docRef, data);
      console.log("Document created successfully.");
    }
  } catch (error) {
    console.error("Error updating or creating document: ", error);
    throw error; // エラーを呼び出し元に伝える
  }
}

export async function getUserPublic(userId: any): Promise<any> {
  const db = getFirestore(otherApp);
  let q = doc(db, "userpublic", userId);
  let docRef = await getDoc(q);
  return docRef;
}

export async function getListOfUsersPublic(list: any[]): Promise<any> {
  const db = getFirestore(otherApp);
  const collectionRef = collection(db, "userpublic");

  // documentId() と "in" 演算子を使用してクエリを作成
  const q = query(collectionRef, where(documentId(), "in", list));
  const querySnapshot = await getDocs(q);

  return querySnapshot;
}
