import { useEffect, useState } from "react";
import { otherApp } from "../../../common/firebaseHelper/firebase";
import { fetchSignInMethodsForEmail } from "firebase/auth";
import {
  Button,
  Grid,
  TextField,
  Typography,
  Box,
  Divider,
  Link,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import LodingModal from "../../Modal/LodingModal/LodingModal";

import Facebook from "@mui/icons-material/Facebook";
// import Twitter from "@mui/icons-material/Twitter";
import XIcon from '@mui/icons-material/X';
import GoogleIcon from "@mui/icons-material/Google";
import { useNavigate } from "react-router-dom";
import {
  getAuth,
  setPersistence,
  signInWithEmailAndPassword,
  browserLocalPersistence,
  FacebookAuthProvider,
  TwitterAuthProvider,
  GoogleAuthProvider,
  signInWithRedirect,
  signInWithPopup,
  getRedirectResult,
  sendPasswordResetEmail,
  onAuthStateChanged,
} from "firebase/auth";

import * as yup from "yup";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  VALIDATION_EMAIL,
  VALIDATION_ERR_REQUIRED,
} from "../../../common/validation";

const schema = yup.object({
  email: yup.string().required(VALIDATION_ERR_REQUIRED).email(VALIDATION_EMAIL),
  password: yup.string().required(VALIDATION_ERR_REQUIRED),
});

const Signin = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // const classes = useStyles();
  const navigate = useNavigate();
  const [errormessage, setErrorMessage] = useState("");
  const [errormessageoauth, setErrorMessageoauth] = useState("");
  const [lodingmodalopen, setLodingmodalopen] = useState(true);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const customTheme = createTheme({
    components: {
      MuiButton: {
        styleOverrides: {
          outlined: {
            backgroundColor: "#dddddd",
          },
        },
      },
    },
    palette: {
      primary: {
        main: "#0066FF",
      },
    },
  });

  useEffect(() => {
    console.log("uE_Signin");
    onAuthStateChanged(getAuth(otherApp), async (user) => {
      if (user) {
        // console.log("Login");
        // props.update();
        window.scrollTo(0, 0);
        navigate("/Book");
      } else {
        // console.log("noLogin");
        setLodingmodalopen(false);
      }
    });
  }, []);

  const handleSubmitOauth = async (provider) => {
    setErrorMessageoauth("別ページが立ち上がりますので、認証ください。");
    const auth = getAuth(otherApp);
    //認証情報をいつまで保持するか設定
    setPersistence(auth, browserLocalPersistence)
      .then(() => {
        //ログインページにリダイレクトしてログイン
        signInWithPopup(auth, provider)
          .then((result) => {
            setErrorMessageoauth("認証完了");
          })
          .catch( async (error) => {


            if (error.code === 'auth/account-exists-with-different-credential') {
              // const pendingCred = error.credential;
              // const email = error.customData.email;
              setErrorMessageoauth("このメールアドレスは異なるプロバイダで既に使用されています。他のサインインを試してください。");

                  
            } else {
              const errorMessage = getErrorMessage(error);
              setErrorMessageoauth(`認証失敗: ${errorMessage}`);
              console.log(errorMessage);
            }
          });
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        setErrorMessageoauth(`認証失敗: ${errorMessage}`);
        console.log(errorMessage);
      });
  };

  const getErrorMessage = (error) => {
    switch (error.code) {
      case "auth/popup-closed-by-user":
        return "ユーザーがポップアップを閉じました。";
      case "auth/cancelled-popup-request":
        return "複数のポップアップが開かれるのを防ぐため、リクエストがキャンセルされました。";
      case "auth/network-request-failed":
        return "ネットワークエラーが発生しました。";
      default:
        return error.message || "不明なエラーが発生しました。";
    }
  };
  

  async function handleSignin() {
    setPersistence(getAuth(otherApp), browserLocalPersistence)
      .then(() => {
        return signInWithEmailAndPassword(
          getAuth(otherApp),
          email.value,
          password.value
        )
          .then((userCredential) => {
            // Signed in
            //console.log(userCredential)
            // props.update();
            window.scrollTo(0, 0);

            navigate("/");
          })
          .catch((error) => {
            const errorMessage = (() => {
              switch (error.code) {
                case "auth/user-not-found":
                  return "メールアドレスが違います";
                case "auth/wrong-password":
                  return "メールアドレスもしくはPWが間違っています";
                default:
                  return "認証に失敗しました。しばらく時間をおいて再度お試しください";
              }
            })();
            setErrorMessage(errorMessage);
          });
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        setErrorMessage(
          "認証に失敗しました。しばらく時間をおいて再度お試しください"
        );
        // console.log(errorCode);
        // console.log(errorMessage);
      });
  }

  async function handleSignup() {
    window.scrollTo(0, 0);
    navigate("/Signup");
  }

  async function handleRePasswordSet() {
    if (email.value) {
      const actionCodeSettings = {
        url: `https://lonpa.net/Signin`,
      };
      sendPasswordResetEmail(getAuth(otherApp), email.value, actionCodeSettings)
        .then(() => {
          alert("password再設定用のメールを送りました");
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(errorCode);
          console.log(errorMessage);
          alert("メール送信に失敗しました");
        });
    } else {
      alert("上部のemail欄を記入して，もう一度ボタンを押してください");
    }
  }

  return (
    <div
      style={{
        maxWidth: "500px",
        margin: "0 auto",
      }}
    >
      <Typography align="left" fontWeight="bold" variant="h6">
        <LockOutlinedIcon style={{ margin: "9px" }} />
        登録済のメールでサインイン
      </Typography>

      <Controller
        control={control}
        name="email"
        render={({ field: { onChange } }) => (
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            onChange={(e) => {
              onChange(e);
              setEmail({ value: e.target.value });
            }}
          />
        )}
      />
      <Typography>{errors.email?.message}</Typography>
      <Controller
        control={control}
        name="password"
        render={({ field: { onChange } }) => (
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={(e) => {
              onChange(e);
              setPassword({ value: e.target.value });
            }}
          />
        )}
      />
      <Typography>{errors.password?.message}</Typography>
      <div style={{ color: "red", fontSize: 15 }}>{errormessage}</div>
      <ThemeProvider theme={customTheme}>
        <Button
          fullWidth
          variant="contained"
          // className={classes.submit}
          onClick={handleSubmit(handleSignin)}
        >
          LOGIN
        </Button>
        <div style={{ height: "1vh" }}></div>
        <Typography display="block">
          新規アカウントを作成する場合は
          <Link href="#" color="#6495ed" onClick={handleSignup}>
            こちら
          </Link>
        </Typography>
        <Typography>
          <Link href="#" color="#6495ed" onClick={handleRePasswordSet}>
            パスワードを忘れた方
          </Link>
        </Typography>
      </ThemeProvider>

      <div style={{ height: "4vh" }}></div>
      <Divider />

      <Box
        sx={{
          pb: 1,
          pt: 3,
        }}
      >
        <Typography align="left" fontWeight="bold" variant="h6">
          <LockOutlinedIcon style={{ margin: "9px" }} />
          アカウント連携でサインイン
        </Typography>
      </Box>

      <Grid container spacing={3}>
        {/* <Grid item xs={12} md={6}>
          <ThemeProvider theme={customTheme}>
            <Button
              fullWidth
              startIcon={<Facebook />}
              onClick={() => {
                handleSubmitOauth(new FacebookAuthProvider());
              }}
              variant="contained"
            >
              Facebook
            </Button>
          </ThemeProvider>
        </Grid> */}
        <Grid item xs={12} md={6}>
          <ThemeProvider theme={customTheme}>
            <Button
              fullWidth
              startIcon={<XIcon />}
              onClick={() => {
                handleSubmitOauth(new TwitterAuthProvider());
              }}
              variant="contained"
            >
              X
            </Button>
          </ThemeProvider>
        </Grid>
        <Grid item xs={12} md={6}>
          <ThemeProvider theme={customTheme}>
            <Button
              fullWidth
              startIcon={<GoogleIcon />}
              onClick={() => {
                handleSubmitOauth(new GoogleAuthProvider());
              }}
              variant="contained"
            >
              Google
            </Button>
          </ThemeProvider>
        </Grid>
      </Grid>
      <div style={{ color: "red", fontSize: 15 }}>{errormessageoauth}</div>

      <div style={{ height: "5vh" }}></div>
      <LodingModal open={lodingmodalopen} setOpen={setLodingmodalopen} />
    </div>
  );
};

export default Signin;
