import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Datas, Route } from "../../../common/types";
import { useParams } from "react-router-dom";

import {
  Button,
  Grid,
  TextField,
  Container,
  Typography,
  Box,
  Card,
  CardContent,
  Divider,
  CardHeader,
  Link,
  IconButton,
} from "@mui/material";

import { otherApp } from "../../../common/firebaseHelper/firebase";

import { addEvent } from "../../../common/firebaseHelper/FirebaseTransactionHelperEvent";
import { getUserPublic } from "../../../common/firebaseHelper/FirebaseTransactionHelperUser";
import {
  setUserPrivateBook,
  getUserPrivateBook,
  deleteUserPrivateBook,
  getUserPrivatePlan,
  setUserPrivatePlan,
} from "../../../common/firebaseHelper/FirebaseTransactionHelperUserPrivate";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import Userpage from "../Userpage/Userpage";

type Props = {
  datas: Datas;
  setDatas: Dispatch<SetStateAction<Datas>>;
  routeList: Route[];
};

const CreateEvent: React.FC<Props> = ({ datas, setDatas, routeList }) => {
  const navigate = useNavigate();
  const [title, setTitle] = useState("");

  useEffect(() => {
    const f = async () => {};
    f();
  }, []);

  const createNewPlan = async (event_title: string) => {
    const auth = getAuth(otherApp);
    const user: any = auth.currentUser;
    if (user && event_title) {
      const docRef = await addEvent({
        title: event_title,
        creater: user.uid,
        EventBBSCount: 0,
        ParticipantsCount: 0,
        priority: 1,
      });
      console.log(docRef.id);
      await setUserPrivatePlan(user.uid, docRef.id, {
        id: docRef.id,
        title: event_title,
      });
      navigate("/Book/");
    } else {
      console.log("ログインしていないか、あるいはタイトルが空です。");
    }
  };

  const centerStyle = {
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
    justifyContent: "center",
    height: "100vh", //画面の高さいっぱいに表示する場合
  };

  return (
    <div style={centerStyle}>
      <div
        style={{
          fontFamily: "'Roboto', sans-serif",
          backgroundColor: "#f4f4f4",
          color: "#333",
          margin: 0,
          padding: "20px",
        }}
      >
        どんなイベントを作りますか?
        <TextField
          label="表示名"
          name="Name"
          value={title}
          variant="standard"
          fullWidth
          margin="dense"
          onChange={(e) => {
            setTitle(e.target.value);
          }}
          sx={{
            input: {
              color: "#000000",
            },
          }}
          style={{
            border: "#000000",
          }}
          InputLabelProps={{
            style: {
              color: "#000000",
            }, // ここに希望の色を指定
          }}
        />
        <Button
          style={{
            display: "inline-block",
            padding: "10px 10px",
            fontSize: "18px",
            color: "#fff",
            backgroundColor: "#3498db",
            textDecoration: "none",
            borderRadius: "5px",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            transition: "background-color 0.3s ease",
          }}
          onClick={() => {
            createNewPlan(title);
          }}
        >
          作成
        </Button>
      </div>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => {
          navigate("/Book");
        }}
        style={{ fontSize: 20, width: "80%" }}
      >
        戻る
      </Button>
    </div>
  );
};

export default CreateEvent;
