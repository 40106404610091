import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Datas, Route } from "../../../common/types";
import { useParams } from "react-router-dom";
import { Button, TextField } from "@mui/material";
import {
  TimePicker,
  DatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { otherApp } from "../../../common/firebaseHelper/firebase";
import moment from "moment";

import {
  getEvent,
  updateEvent,
} from "../../../common/firebaseHelper/FirebaseTransactionHelperEvent";

import { getAuth } from "firebase/auth";

type Props = {
  datas: Datas;
  setDatas: Dispatch<SetStateAction<Datas>>;
  routeList: Route[];
};

const EditEvent: React.FC<Props> = ({ datas, setDatas, routeList }) => {
  let { eventid } = useParams();
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [todayDate, setTodayDate] = useState<Date>(new Date(0));
  const [todayString, setTodayString] = useState("");
  const [eventDate, setEventDate] = useState<Date>(new Date(0));
  const [eventDateString, setEventDateString] = useState("");

  const parseAsMoment = (dateTimeStr: any) => {
    return moment.utc(dateTimeStr, "YYYY-MM-DDTHH:mm:00Z", "ja").utcOffset(9);
  };

  useEffect(() => {
    console.log("ue_EditEvent");
    const f = async () => {
      //現在日付データ作成
      const todaydate = new Date();
      const todaystring = parseAsMoment(todaydate)
        .format("YYYY/MM/DD HH:mm")
        .toString();

      setTodayDate(todaydate);
      setTodayString(todaystring);

      //イベントの情報を取得
      let doc = await getEvent(eventid);
      setTitle(doc.data().title);

      if (doc.data().eventDate) {
        setEventDate(doc.data().eventDate.toDate());
        const eventdatestring = parseAsMoment(doc.data().eventDate.toDate())
          .format("YYYY/MM/DD HH:mm")
          .toString();
        setEventDateString(eventdatestring);
      } else {
        //日付が設定されていなければ、初期値として本日の日付を入れる
        setEventDate(todaydate);
        setEventDateString(todaystring);
      }
    };
    f();
  }, []);

  const updatePlan = async (event_title: string, event_date: Date) => {
    const auth = getAuth(otherApp);
    const user: any = auth.currentUser;
    if (user && event_title) {
      const docRef = await updateEvent(eventid, {
        title: event_title,
        eventDate: event_date,
      });
      navigate("/Book/");
    } else {
      console.log("ログインしていないか、あるいはタイトルが空です。");
    }
  };

  const centerStyle = {
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
    justifyContent: "center",
    height: "100vh", //画面の高さいっぱいに表示する場合
  };

  return (
    <div style={centerStyle}>
      <div
        style={{
          fontFamily: "'Roboto', sans-serif",
          backgroundColor: "#f4f4f4",
          color: "#333",
          margin: 0,
          padding: "20px",
        }}
      >
        編集
        <TextField
          label="表示名"
          name="Name"
          value={title}
          variant="standard"
          fullWidth
          margin="dense"
          onChange={(e) => {
            setTitle(e.target.value);
          }}
          sx={{
            input: {
              color: "#000000",
            },
          }}
          style={{
            border: "#000000",
          }}
          InputLabelProps={{
            style: {
              color: "#000000",
            }, // ここに希望の色を指定
          }}
        />
        <br />
        {/* 本日は{todayString} */}
        <br />
        開催日 :
        <br />
        <br />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="日付を選択"
            value={dayjs(eventDate)}
            format="YYYY/MM/DD"
            onChange={(newValue: any) => {
              const eventDateString = parseAsMoment(newValue.toDate())
                .format("YYYY/MM/DD HH:mm")
                .toString();
              setEventDateString(eventDateString);
              setEventDate(newValue ? newValue.toDate() : null);
            }}
            // renderInput={(params) => <TextField {...params} />}
          />
          <TimePicker
            label="開始時間を選択"
            value={dayjs(eventDate)}
            onChange={(newValue: any) => {
              const eventDateString = parseAsMoment(newValue.toDate())
                .format("YYYY/MM/DD HH:mm")
                .toString();
              setEventDateString(eventDateString);
              setEventDate(newValue ? newValue.toDate() : null);
            }}
          />
        </LocalizationProvider>
        <br />
        <br />
        <Button
          style={{
            display: "inline-block",
            padding: "10px 10px",
            fontSize: "18px",
            color: "#fff",
            backgroundColor: "#3498db",
            textDecoration: "none",
            borderRadius: "5px",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            transition: "background-color 0.3s ease",
          }}
          onClick={() => {
            updatePlan(title, eventDate);
          }}
        >
          変更
        </Button>
      </div>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => {
          navigate("/Book");
        }}
        style={{ fontSize: 20, width: "80%" }}
      >
        戻る
      </Button>
    </div>
  );
};

export default EditEvent;
