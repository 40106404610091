// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: process.env.REACT_APP_apiKey_BoukenGuild,
  authDomain: process.env.REACT_APP_authDomain_BoukenGuild,
  projectId: process.env.REACT_APP_projectId_BoukenGuild,
  storageBucket: process.env.REACT_APP_storageBucket_BoukenGuild,
  messagingSenderId: process.env.REACT_APP_messagingSenderId_BoukenGuild,
  appId: process.env.REACT_APP_appId_BoukenGuild,
  measurementId: process.env.REACT_APP_measurementId_BoukenGuild,
};

const firebaseConfig2 = {
  apiKey: process.env.REACT_APP_apiKey_User,
  authDomain: process.env.REACT_APP_authDomain_User,
  projectId: process.env.REACT_APP_projectId_User,
  storageBucket: process.env.REACT_APP_storageBucket_User,
  messagingSenderId: process.env.REACT_APP_messagingSenderId_User,
  appId: process.env.REACT_APP_appId_User,
  measurementId: process.env.REACT_APP_measurementId_User,
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
// 別のプロジェクトのFirebaseアプリを初期化
export const otherApp = initializeApp(firebaseConfig2, "Lonpa");

const analytics = getAnalytics(firebaseApp);
