import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Datas, Route } from "../../../common/types";
import { useParams } from "react-router-dom";

import { Button } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import EventIcon from "@mui/icons-material/Event";
import ScheduleIcon from "@mui/icons-material/Schedule";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";

import { otherApp } from "../../../common/firebaseHelper/firebase";

import {
  getEvents,
  setEventParticipants,
  deleteEventParticipants,
} from "../../../common/firebaseHelper/FirebaseTransactionHelperEvent";
import { getUserPublic } from "../../../common/firebaseHelper/FirebaseTransactionHelperUser";
import {
  setUserPrivateBook,
  getUserPrivateBook,
  deleteUserPrivateBook,
  getUserPrivatePlan,
  setUserPrivatePlan,
} from "../../../common/firebaseHelper/FirebaseTransactionHelperUserPrivate";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import Footer from "../../Footer/Footer";

import MenuEvent from "./MenuEvent";
import MenuJoin from "./MenuJoin";
import MenuPlan from "./MenuPlan";
import MenuProfile from "./MenuProfile";

type Props = {
  datas: Datas;
  setDatas: Dispatch<SetStateAction<Datas>>;
  routeList: Route[];
};

const Book: React.FC<Props> = ({ datas, setDatas, routeList }) => {
  const navigate = useNavigate();
  let { ekiid } = useParams();

  const [eventList, setEventList] = useState([]);
  const [eventPastList, setEventPastList] = useState([]);
  const [eventFutureList, setEventFutureList] = useState([]);

  const [eventJoinList, setEventJoinList] = useState([]);
  const [eventPlanList, setEventPlanList] = useState([]);

  const [isLogin, setIsLogin] = useState(false);
  const [opendMenu, setOpendMenu] = useState("profile");
  // const [isMemberOpen, setIsMemberOpen] = useState(false);

  const [changeCount, setChangeCount] = useState(0);
  const [errorText, setErrorText] = useState("");

  useEffect(() => {
    const f = async () => {
      const querySnapshot = await getEvents();
      let lists: any = [];
      querySnapshot.forEach((doc: any) => {
        lists.push({
          id: doc.id,
          title: doc.data().title,
          eventDate: doc.data().eventDate,
          priority: doc.data().priority,
          isActive: true,
          disabled: false,
        });
      });
      setEventList(lists);

      const todaydate = new Date();
      const listPast: any = [];
      const listFuture: any = [];

      lists.forEach((event: any) => {
        if (event.eventDate) {
          const eventDate = event.eventDate.toDate();
          if (eventDate < todaydate) {
            listPast.push(event);
          } else {
            listFuture.push(event);
          }
        }
      });
      setEventPastList(listPast);
      setEventFutureList(listFuture);

      const usercheck = onAuthStateChanged(getAuth(otherApp), async (user) => {
        // console.log("通過");
        // console.log(user)
        if (user) {
          setIsLogin(true);
          // console.log("ログインしている");

          const querySnapshot2 = await getUserPrivateBook(user.uid);
          //ログイン中のアカウントで，選択中のlonpa一覧
          let lists2: any = [];
          querySnapshot2.forEach((doc: any) => {
            // console.log(doc.id);
            lists2.push({
              id: doc.data().id,
              title: doc.data().title,
            });
          });
          setEventJoinList(lists2);

          // EventList と EventJoinList を突合
          const updatedLists = lists.map((event: any) => {
            const isJoined = lists2.some(
              (joined: any) => joined.id === event.id
            );
            if (isJoined) {
              return { ...event, disabled: true };
            }
            return event;
          });

          // 更新された EventList をセット
          setEventList(updatedLists);

          const updatedlistPast: any = [];
          const updatedlistFuture: any = [];
          updatedLists.forEach((event: any) => {
            if (event.eventDate) {
              const eventDate = event.eventDate.toDate();
              if (eventDate < todaydate) {
                updatedlistPast.push(event);
              } else {
                updatedlistFuture.push(event);
              }
            }
          });
          setEventPastList(updatedlistPast);
          setEventFutureList(updatedlistFuture);

          const querySnapshot3 = await getUserPrivatePlan(user.uid);
          //ログイン中のアカウントで，選択中のlonpa一覧
          let lists3: any = [];
          querySnapshot3.forEach((doc: any) => {
            // console.log(doc.id);
            lists3.push({
              id: doc.data().id,
              title: doc.data().title,
            });
          });
          setEventPlanList(lists3);
        } else {
          setIsLogin(false);
        }
      });

      return () => usercheck();
    };
    f();
  }, [changeCount]);

  const incrementChangeCount = () => {
    setChangeCount(changeCount + 1);
  };

  const cancelUser = async (event_id: string, event_title: string) => {
    const auth = getAuth(otherApp);
    const user = auth.currentUser;
    if (user) {
      await deleteEventParticipants(event_id, user.uid);
      await deleteUserPrivateBook(user.uid, event_id);
      incrementChangeCount();
    } else {
      // console.log("ログインしていない");
    }
  };

  const bookUser = async (event_id: string, event_title: string) => {
    const auth = getAuth(otherApp);
    const user = auth.currentUser;
    if (user) {
      let doc = await getUserPublic(user.uid);
      if (typeof doc.data() !== "undefined") {
        if (typeof doc.data().name !== "undefined") {
          await setEventParticipants(event_id, user.uid, {
            uid: user.uid,
            name: doc.data().name,
          });
          await setUserPrivateBook(user.uid, event_id, {
            id: event_id,
            title: event_title,
          });
          incrementChangeCount();
        } else {
          console.log("name undfined");
          setErrorText(
            "ユーザに名前が設定されていないようです。プロフィール欄で設定してください。"
          );
        }
      } else {
        console.log("data undfined");
        setErrorText(
          "ユーザに名前が設定されていないようです。プロフィール欄で設定してください。"
        );
      }
    } else {
      // console.log("ログインしていない");
      navigate("/Signin/");
    }
  };

  const centerStyle = {
    // display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
    justifyContent: "center",
    height: "100vh", //画面の高さいっぱいに表示する場合
  };

  return (
    <div style={centerStyle}>
      <div
        style={{
          backgroundColor: "#bbbbbb",
        }}
      >
        <table
          style={{
            width: "100%",
            textAlign: "center", // Horizontally centers the content
            verticalAlign: "middle", // Vertically centers the content
          }}
        >
          <tbody>
            <tr>
              <td
                style={{
                  width: "25%",
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => {
                    setOpendMenu("profile");
                  }}
                  style={{
                    width: "90%",
                    border: "none", // 枠をなくす
                  }}
                >
                  <PersonIcon style={{ color: "#555555" }} />
                </Button>
              </td>

              <td
                style={{
                  width: "25%",
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => {
                    setOpendMenu("event");
                  }}
                  style={{
                    width: "90%",
                    border: "none", // 枠をなくす
                  }}
                >
                  <EventIcon style={{ color: "#555555" }} />
                </Button>
              </td>
              <td
                style={{
                  width: "25%",
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => {
                    setOpendMenu("join");
                  }}
                  style={{
                    width: "90%",
                    border: "none", // 枠をなくす
                  }}
                >
                  <EventAvailableIcon style={{ color: "#555555" }} />
                </Button>
              </td>
              <td
                style={{
                  width: "25%",
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => {
                    setOpendMenu("plan");
                  }}
                  style={{
                    width: "90%",
                    border: "none", // 枠をなくす
                  }}
                >
                  <EditCalendarIcon style={{ color: "#555555" }} />
                </Button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        style={{
          fontFamily: "'Roboto', sans-serif",
          // backgroundColor: "#f4f4f4",
          color: "#333",
          margin: 0,
        }}
      >
        <h1
          style={{
            fontFamily: "'Playfair Display', serif",
            fontSize: "35px",
            fontWeight: 700,
            color: "#2c3e50",
            textAlign: "center",
            textShadow: "2px 2px 5px rgba(0, 0, 0, 0.1)",
            marginBottom: "20px",
          }}
        >
          山手散歩会
        </h1>
        {/* <h2
          style={{
            fontFamily: "'Roboto', sans-serif",
            fontSize: "24px",
            color: "#34495e",
            textAlign: "center",
            textDecoration: "underline",
            textDecorationColor: "#e74c3c",
            marginBottom: "40px",
          }}
        >
          散歩会に参加しよう
        </h2> */}

        <p
          style={{
            fontSize: "17px",
            lineHeight: 1.6,
            // textAlign: "justify",
            textAlign: "center",
          }}
        >
          <span
            style={{
              backgroundColor: "#f9e79f",
              padding: "0 5px",
              borderRadius: "3px",
            }}
          >
            イベント
          </span>
          を見つけて、参加してみよう！
        </p>

        <br />
        <div>
          {opendMenu === "profile" ? (
            <MenuProfile
              isLogin={isLogin}
              incrementChangeCount={incrementChangeCount}
            />
          ) : opendMenu === "event" ? (
            <MenuEvent
              isLogin={isLogin}
              // eventList={eventList}
              eventPastList={eventPastList}
              eventFutureList={eventFutureList}
              cancelUser={cancelUser}
              bookUser={bookUser}
              errorText={errorText}
            />
          ) : opendMenu === "join" ? (
            <MenuJoin
              isLogin={isLogin}
              eventJoinList={eventJoinList}
              cancelUser={cancelUser}
            />
          ) : opendMenu === "plan" ? (
            <MenuPlan isLogin={isLogin} eventPlanList={eventPlanList} />
          ) : (
            <div>選んでみよう</div>
          )}
        </div>
      </div>
      <br />

      <Button
        variant="outlined"
        color="primary"
        onClick={() => {
          navigate("/");
        }}
        style={{
          fontSize: 18,
          width: "90%",
          textAlign: "center",
          margin: "0 5%",
        }}
      >
        TOPへ戻る
      </Button>
      <br />
      <br />
      <Footer />
    </div>
  );
};

export default Book;
