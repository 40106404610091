import { firebaseApp } from "./firebase";
import {
  doc,
  collection,
  getFirestore,
  runTransaction,
  query,
  getDocs,
  orderBy,
  limit,
  addDoc,
  setDoc,
  deleteDoc,
  updateDoc,
  documentId,
  where,
} from "firebase/firestore";

export async function getDatas(): Promise<any> {
  const db = getFirestore(firebaseApp);
  let q = query(collection(db, "YamanoteRoute"), orderBy("num"), limit(30));
  const querySnapshot = await getDocs(q);
  return querySnapshot;
}
export async function addData(data: any): Promise<any> {
  const db = getFirestore(firebaseApp);
  let q = collection(db, "YamanoteRoute");
  let docRef = await addDoc(q, data);
  return docRef;
}

export async function updateData(id: string, data: any): Promise<any> {
  const db = getFirestore(firebaseApp);
  let q = doc(db, "YamanoteRoute", id);
  let docRef = await setDoc(q, data);
  return docRef;
}

export async function getCommands(id: string): Promise<any> {
  const db = getFirestore(firebaseApp);
  let q = query(
    collection(db, "YamanoteRoute", id, "Command"),
    orderBy("num"),
    limit(100)
  );
  const querySnapshot = await getDocs(q);
  return querySnapshot;
}
export async function addCommand(id: string, data: any): Promise<any> {
  const db = getFirestore(firebaseApp);
  let q = collection(db, "YamanoteRoute", id, "Command");
  let docRef = await addDoc(q, data);
  return docRef;
}

export async function deleteCommand(id: string, shiziid: string): Promise<any> {
  const db = getFirestore(firebaseApp);
  let q = doc(db, "YamanoteRoute", id, "Command", shiziid);
  //console.log(db, "YamanoteRoute", id, "Command", shiziid);
  // let docRef = await addDoc(q, data);
  await deleteDoc(q);
  return true;
}

export async function setCommand(
  id: string,
  id2: string,
  data: any,
  openingtime: any,
  closingtime: any
): Promise<any> {
  const db = getFirestore(firebaseApp);
  let q = doc(db, "YamanoteRoute", id, "Command", id2);
  data.openingtime = openingtime;
  data.closingtime = closingtime;
  let docRef = await setDoc(q, data);
  return docRef;
}

export async function updateCommand(
  id: string,
  id2: string,
  data: any
): Promise<any> {
  const db = getFirestore(firebaseApp);
  let q = doc(db, "YamanoteRoute", id, "Command", id2);
  let docRef = await setDoc(q, data);
  return docRef;
}

export async function get3commands(id: any, itemlist: any[]) {
  const db = getFirestore(firebaseApp);
  const collectionRef = collection(db, "YamanoteRoute", id, "Command");

  let list3 = [];
  let querySnapshot;
  let q;
  let alllistdatas: any[] = [];

  for (let i = 0; i < 3; i++) {
    list3.push(itemlist[i]);
  }
  //list10の要素が10個になったらfirebaseにデータをとりにいく
  q = query(collectionRef, where(documentId(), "in", list3));
  querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    alllistdatas.push(doc);
  });

  return alllistdatas;
}

// export async function getData(pickid:string) {
//     const db = getFirestore(firebaseApp);
//     let sfDoc;

//     try {
//       await runTransaction(db, async (transaction) => {
//         const sfDocRef = doc(
//           db,
//           "data",
//           pickid
//         );
//         sfDoc = await transaction.get(sfDocRef);
//         if (!sfDoc.exists()) {
//           throw "Document does not exist!";
//         }
//       });
//       return sfDoc;
//     } catch (e) {
//       console.log("Transaction failed: ", e);
//       return false;
//     }
//   }

//   export async function createData(id:string, setdata:any) {
//     const db = getFirestore(firebaseApp);

//     try {
//       await runTransaction(db, async (transaction) => {
//           const sfDocRef = doc(db, "data", id);

//           await transaction.set(sfDocRef, {
//             isflagone: setdata.isflagone,
//             isflagtwo: setdata.isflagtwo,
//             isflagthree: setdata.isflagthree,
//             isflagfour: setdata.isflagfour,
//             isflagfive: setdata.isflagfive,
//             claim: setdata.claim,
//           });
//         }
//       );
//       return true;
//     } catch (e) {
//       console.log("Transaction failed: ", e);
//       return false;
//     }
//   }
