import React, { useReducer, useEffect, useState } from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import "./App.css";
import Top from "./components/Top/Top";
import Ekisentaku from "./components/views/Ekisentaku/Ekisentaku";
import Shizigatya from "./components/views/Shizigatya/Shizigatya";
import Gatyaresult from "./components/views/Gatyaresult/Gatyaresult";
import Sanponaiyou from "./components/views/Sanponaiyou/Sanponaiyou";
import Tsukaikata from "./components/views/Tsukaikata/Tsukaikata";
import D from "./components/views/D/D";
import Book from "./components/views/Book/Book";
import Signin from "./components/views/Signin/Signin";
import Signup from "./components/views/Signup/Signup";
import Userpage from "./components/views/Userpage/Userpage";
import Event from "./components/views/Event/Event";
import CreateEvent from "./components/views/CreateEvent/CreateEvent";
import EditEvent from "./components/views/EditEvent/EditEvent";

import { getDatas } from "./common/firebaseHelper/FirebaseTransactionHelper";

function App() {
  const [datas, setDatas] = useState({
    place: {
      num: 0,
      text: "東京",
      id: "XizOo6WOXHjMK01o6htz",
      describe: "東京",
    },
    step: { num: 0, text: "0ターン目" },
    moveList: [{ num: 0, text: "東京に到着" }],
  });
  const [routeList, setRouteList] = useState([]);

  useEffect(() => {
    const f = async () => {
      const querySnapshot = await getDatas();
      //ログイン中のアカウントで，選択中のlonpa一覧

      let lists: any = [];

      querySnapshot.forEach((doc: any) => {
        if (
          // doc.data().num == 18 ||
          // doc.data().num == 19 ||
          // doc.data().num == 20 ||
          // doc.data().num == 4 ||
          // doc.data().num == 6 ||
          // doc.data().num == 0
          true
        ) {
          lists.push({
            id: doc.id,
            num: doc.data().num,
            text: doc.data().text,
            describe: doc.data().describe,
            isActive: true,
          });
        } else {
          lists.push({
            id: doc.id,
            num: doc.data().num,
            text: doc.data().text,
            isActive: false,
          });
        }
      });

      setRouteList(lists);
    };
    f();
  }, []);

  return (
    <BrowserRouter>
      {/* <TopBar datas={datas} setDatas={setDatas} /> */}
      <Routes>
        <Route path="/" element={<Top datas={datas} setDatas={setDatas} />} />
        <Route
          path="/ekisentaku"
          element={
            <Ekisentaku
              datas={datas}
              setDatas={setDatas}
              routeList={routeList}
            />
          }
        />
        <Route
          path="/shizigatya/:ekiid"
          element={
            <Shizigatya
              datas={datas}
              setDatas={setDatas}
              routeList={routeList}
            />
          }
        />
        <Route
          path="/gatyaresult/:ekiid/:id1/:id2/:id3"
          element={
            <Gatyaresult
              datas={datas}
              setDatas={setDatas}
              routeList={routeList}
            />
          }
        />
        <Route
          path="/sanponaiyou/:ekiid/:id1"
          element={
            <Sanponaiyou
              datas={datas}
              setDatas={setDatas}
              routeList={routeList}
            />
          }
        />
        <Route
          path="/tsukaikata"
          element={
            <Tsukaikata
              datas={datas}
              setDatas={setDatas}
              routeList={routeList}
            />
          }
        />
        <Route
          path="/D"
          element={
            <D datas={datas} setDatas={setDatas} routeList={routeList} />
          }
        />
        <Route
          path="/Book"
          element={
            <Book datas={datas} setDatas={setDatas} routeList={routeList} />
          }
        />
        <Route
          path="/Signin"
          element={
            <Signin datas={datas} setDatas={setDatas} routeList={routeList} />
          }
        />
        <Route
          path="/Signup"
          element={
            <Signup datas={datas} setDatas={setDatas} routeList={routeList} />
          }
        />
        <Route
          path="/Userpage"
          element={
            <Userpage datas={datas} setDatas={setDatas} routeList={routeList} />
          }
        />
        <Route
          path="/CreateEvent"
          element={
            <CreateEvent
              datas={datas}
              setDatas={setDatas}
              routeList={routeList}
            />
          }
        />
        <Route
          path="/Event/:eventid"
          element={
            <Event datas={datas} setDatas={setDatas} routeList={routeList} />
          }
        />

        <Route
          path="/EditEvent/:eventid"
          element={
            <EditEvent
              datas={datas}
              setDatas={setDatas}
              routeList={routeList}
            />
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
