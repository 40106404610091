import {
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
  useCallback,
  memo,
} from "react";
import { useNavigate } from "react-router-dom";

import dayjs from "dayjs";

import { Datas, Route, Command } from "../../../common/types";
import ImageSetting from "../../../common/lib/imageSetting";

import {
  Button,
  Grid,
  TextField,
  Container,
  Typography,
  Box,
  Link,
  Checkbox,
} from "@mui/material";

import { TimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Timestamp } from "firebase/firestore"; // FirestoreのTimestamp型をインポート
import {
  getCommands,
  addCommand,
  updateCommand,
  deleteCommand,
  updateData,
} from "../../../common/firebaseHelper/FirebaseTransactionHelper";

type Props = {
  datas: Datas;
  setDatas: Dispatch<SetStateAction<Datas>>;
  routeList: Route[];
};

const centerStyle = {
  display: "flex",
  flexDirection: "column" as const,
  alignItems: "center",
  justifyContent: "center",
  // height: "100vh", //画面の高さいっぱいに表示する場合
};

const lineStyle = {
  display: "inline-block",
  width: "100vw",
};

const D: React.FC<Props> = ({ datas, setDatas, routeList }) => {
  const [text1, settext1] = useState<string>();
  const [text2, settext2] = useState("");
  const [eki_id, seteki_id] = useState("");
  const [eki_describe, setEki_describe] = useState("");
  const [text4, settext4] = useState("");
  const [text5, settext5] = useState("");
  const [shizi_id, settext6] = useState("");
  const [openingtime, setopeningtime] = useState<Date>(new Date(0));
  const [closingtime, setclosingtime] = useState<Date>(new Date(0));
  const [explainText, setExplainText] = useState("まずは駅を選んでね");
  const [changeType, setChangeType] = useState(true);
  const [changing, setChanging] = useState(true);
  const [ekichanging, setEkiChanging] = useState(true);

  const [commandList, setCommandList] = useState<Command[]>([]);
  const [imageurl, setimageurl] = useState("");
  const navigate = useNavigate();

  //指令を取得する処理
  async function getDataCommand(id: string): Promise<boolean> {
    const querySnapshot = await getCommands(id);

    let lists: any = [];
    querySnapshot.forEach((doc: any) => {
      let openingtimenew;
      let closingtimenew;
      if (doc.data().openingtime instanceof Timestamp) {
        openingtimenew = doc.data().openingtime.toDate();
      } else {
        openingtimenew = new Date(0);
      }

      if (doc.data().closingtime instanceof Timestamp) {
        closingtimenew = doc.data().closingtime.toDate();
      } else {
        closingtimenew = new Date(0);
      }

      lists.push({
        id: doc.id,
        num: doc.data().num,
        text: doc.data().text,
        imageurl: doc.data().imageURL,
        openingtime: openingtimenew,
        closingtime: closingtimenew,
      });
    });

    setCommandList(lists);

    return true;
  }
  return (
    <div style={centerStyle}>
      <div style={{ textAlign: "center" }}>
        <br />
        <br />
        ！！！注意！！！
        <br />
        <br />
        管理者以外こちらは利用しないでください。
        <br />
        <br />
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            navigate("/");
          }}
          style={{ fontSize: 20 }}
        >
          戻る
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            navigate("/Book");
          }}
          style={{ fontSize: 20 }}
        >
          予約ページ
        </Button>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
      <div style={lineStyle}>
        {routeList.map((item, index) => (
          <Button
            variant="outlined"
            key={item.id}
            onClick={() => {
              settext1(String(item.num));
              settext2(String(item.text));
              seteki_id(String(item.id));
              setEki_describe(String(item.describe));
              settext4(String());
              settext5(String());
              settext6(String());
              setopeningtime(new Date(0));
              setclosingtime(new Date(0));
              getDataCommand(String(item.id));
              setChanging(true);
              setEkiChanging(false);
              setExplainText("変更・追加したい指令を選ぼう");
            }}
          >
            {item.num}:{item.text}
          </Button>
        ))}
      </div>
      <br /> <br />
      <TextField
        fullWidth
        multiline
        maxRows={3}
        minRows={1}
        label="駅番号"
        value={text1}
        onChange={(e) => settext1(e.target.value)}
        disabled
      />
      <TextField
        fullWidth
        multiline
        maxRows={3}
        minRows={1}
        label="駅名"
        value={text2}
        onChange={(e) => settext2(e.target.value)}
        disabled
      />
      <TextField
        fullWidth
        multiline
        maxRows={3}
        minRows={1}
        label="駅ID"
        value={eki_id}
        onChange={(e) => seteki_id(e.target.value)}
        disabled
      />
      <TextField
        fullWidth
        multiline
        maxRows={5}
        minRows={2}
        label="describe"
        value={eki_describe}
        onChange={(e) => {
          setEki_describe(e.target.value);
        }}
      />
      <Button
        variant="outlined"
        color="primary"
        onClick={async () => {
          setChanging(true);
          await updateData(eki_id, {
            num: Number(text1),
            text: String(text2),
            describe: String(eki_describe),
          });
          setTimeout(async () => {
            setChanging(false);
          }, 1000); // 1秒の遅延
        }}
        style={{ fontSize: 20 }}
        disabled={ekichanging}
      >
        駅説明の更新
      </Button>
      <br />
      <br />
      山手線　{text2}駅の特徴について
      <br />
      他の山手線の駅と比較して
      <br />
      50文字以内で教えてください。
      <br />
      <br />
      その特徴とした理由も教えてください
      <br />
      <br />
      <br />
      {commandList.length > 0 ? (
        <>
          <div style={lineStyle}>
            {commandList.map((item2, index) => (
              <Button
                variant="outlined"
                key={item2.id}
                style={{
                  display: "inline-block",
                  whiteSpace: "nowrap",
                  minWidth: "auto",
                }}
                onClick={() => {
                  settext4(String(item2.num));
                  settext5(String(item2.text));
                  settext6(String(item2.id));
                  setimageurl(String(item2.imageurl));
                  setopeningtime(item2.openingtime);
                  setclosingtime(item2.closingtime);
                  setChangeType(true);
                  setChanging(false);
                }}
              >
                {item2.num}:{item2.text}
              </Button>
            ))}
          </div>
          <br />
          <Button
            variant="outlined"
            onClick={() => {
              settext4(String(commandList.length));
              settext5("");
              settext6("自動生成されます");
              setopeningtime(new Date(0));
              setclosingtime(new Date(0));
              setChangeType(false);
              setChanging(false);
            }}
          >
            {commandList.length}:追加する
          </Button>
        </>
      ) : (
        <>
          {explainText}
          <Button
            variant="outlined"
            onClick={() => {
              settext4(String(0));
              settext5("");
              settext6("自動生成されます");
              setopeningtime(new Date(0));
              setclosingtime(new Date(0));
              setChangeType(false);
              setChanging(false);
            }}
          >
            {commandList.length}:追加する
          </Button>
        </>
      )}
      <br />
      <TextField
        fullWidth
        multiline
        maxRows={3}
        minRows={1}
        label="指令番号"
        value={text4}
        onChange={(e) => settext4(e.target.value)}
        disabled
      />
      <TextField
        fullWidth
        multiline
        maxRows={3}
        minRows={1}
        label="指令内容"
        value={text5}
        onChange={(e) => settext5(e.target.value)}
      />
      <TextField
        fullWidth
        multiline
        maxRows={3}
        minRows={1}
        label="指令ID"
        value={shizi_id}
        onChange={(e) => settext6(e.target.value)}
        disabled
      />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {openingtime.toString()}
        <TimePicker
          label="開店時刻"
          value={dayjs(openingtime)}
          onChange={(newValue: any) => setopeningtime(newValue.toDate())}
        />
        {closingtime.toString()}
        <TimePicker
          label="閉店時刻"
          value={dayjs(closingtime)}
          onChange={(newValue: any) => setclosingtime(newValue.toDate())}
        />
      </LocalizationProvider>
      {text1 ? (
        <>
          {changeType ? (
            <>
              {" "}
              <Button
                variant="outlined"
                color="primary"
                onClick={async () => {
                  setChanging(true);
                  await updateCommand(eki_id, shizi_id, {
                    num: Number(text4),
                    text: String(text5),
                    openingtime: openingtime,
                    closingtime: closingtime,
                  });

                  setTimeout(async () => {
                    await getDataCommand(eki_id);
                    setChanging(false);
                  }, 1000); // 1秒の遅延
                }}
                style={{ fontSize: 20 }}
                disabled={changing}
              >
                指令の変更
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={async () => {
                  setChanging(true);
                  await deleteCommand(eki_id, shizi_id);
                  setTimeout(async () => {
                    await getDataCommand(eki_id);
                    setChanging(false);
                  }, 1000); // 1秒の遅延
                }}
                style={{ fontSize: 20 }}
                disabled={changing}
              >
                削除
              </Button>
              {shizi_id ? (
                <>
                  {/* <ImageSetting /> */}
                  {
                    <ImageSetting
                      eki_id={eki_id}
                      shizi_id={shizi_id}
                      imageurl={imageurl}
                      setimageurl={setimageurl}
                      openingtime={openingtime}
                      closingtime={closingtime}
                    />
                  }
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <>
              {" "}
              <Button
                variant="outlined"
                color="primary"
                onClick={async () => {
                  setChanging(true);
                  await addCommand(eki_id, {
                    num: Number(text4),
                    text: String(text5),
                    openingtime: openingtime,
                    closingtime: closingtime,
                  });
                  setTimeout(async () => {
                    await getDataCommand(eki_id);
                    // setChanging(false);
                  }, 1000); // 1秒の遅延
                }}
                style={{ fontSize: 20 }}
                disabled={changing}
              >
                指令の作成
              </Button>
            </>
          )}
          <br />
          山手線　{text2}駅から　徒歩20分圏内で楽しめる場所を教えて
          <br />
          ただし、下記両方の条件を満たすものを10ヶ所選んでください
          <br />・{text2}駅の方が{routeList[(Number(text1) + 1) % 30].text}
          駅より距離が近いこと
          <br />・{text2}駅の方が{routeList[(Number(text1) - 1 + 30) % 30].text}
          駅より距離が近いこと
          <br />
          <br />
          それぞれの場所についてどのくらい
          <br />
          {text2}駅の方が{routeList[(Number(text1) + 1) % 30].text}駅・
          {routeList[(Number(text1) - 1 + 30) % 30].text}
          駅より距離が近いか４０文字以内で教えてください
          <br />
          また、それぞれの場所について観光での魅力について１０文字以内で教えてください
          <br />
          <br />
          最後に場所名だけ一覧化してください
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default D;
