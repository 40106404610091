import {
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
  useCallback,
  memo,
} from "react";

import { getCommands } from "../../../common/firebaseHelper/FirebaseTransactionHelper";
import { useNavigate } from "react-router-dom";
import { Datas, Route } from "../../../common/types";
import { useParams } from "react-router-dom";

import { Button } from "@mui/material";

type Props = {
  datas: Datas;
  setDatas: Dispatch<SetStateAction<Datas>>;
  routeList: Route[];
};

type command = {
  id: string;
  text: string;
};
const centerStyle = {
  display: "flex",
  flexDirection: "column" as const,
  alignItems: "center",
  justifyContent: "center",
  minHeight: "100vh", //画面の高さいっぱいに表示する場合
};

const Shizigatya: React.FC<Props> = ({ datas, setDatas, routeList }) => {
  let { ekiid } = useParams();
  const [start, setStart] = useState(false);
  const [index, setIndex] = useState(0);
  const [flag, setFlag] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [commandList, setCommandList] = useState<command[]>([]);
  // ランダムな3つの指令を保持するためのstateを追加
  const [randomCommands, setRandomCommands] = useState<command[]>([]);
  // 選択された指令を保持するためのstateを追加
  // const [selectedCommand, setSelectedCommand] = useState<command | null>(null);

  const navigate = useNavigate();

  const rouletteContents: command[] = [
    // { id: "common1", text: "面白いものを見つける" },
    // { id: "common2", text: "景色に感想を言う" },
  ];

  useEffect(() => {
    const f = async () => {
      const id: string = ekiid ?? "kdJ30lwneh3eGIVIF09J";
      const querySnapshot = await getCommands(id);

      let lists: any = [];

      querySnapshot.forEach((doc: any) => {
        lists.push({ id: doc.id, text: doc.data().text });
      });

      const combinedArray = [...lists, ...rouletteContents];
      // console.log(combinedArray)

      setCommandList(combinedArray);
      startRoulette();

    };
    f();
  }, []);

  //ボタンの文言を変更する処理
  const startRoulette = useCallback(() => {
    setFlag(true);

    setStart(!start);
  }, [start]);

  // ルーレットを回す処理
  useEffect(() => {
    if (start) {
      const interval = setInterval(() => {
        setRandomCommands(() => {
          const randomIndexes: number[] = [];
          while (randomIndexes.length < 3) {
            const randomIndex = Math.floor(Math.random() * commandList.length);
            if (!randomIndexes.includes(randomIndex)) {
              randomIndexes.push(randomIndex);
            }
          }
          return randomIndexes.map((index) => commandList[index]);
        });
      }, 50); // ルーレットの中身を切り替える速度
      return () => clearInterval(interval);
    } else if (!start) {
      if (flag) {
        setButtonDisabled(true);
        const randomids: string[] = [];
        randomCommands.map((command, i) => {
          //console.log(command.id);
          randomids.push(command.id);
        });
        navigate(
          "/Gatyaresult/" +
            ekiid +
            "/" +
            randomids[0] +
            "/" +
            randomids[1] +
            "/" +
            randomids[2] +
            "/"
        ); // ここでURLに遷移する
      }
      return () => clearInterval(0);
    }
  }, [start]);

  return (
    <>
      <div style={centerStyle}>
        <h1 style={{ color: "skyblue", fontSize: "26px", textAlign: "center" }}>
          {datas.place.text}駅 <br />
          行き先ガチャ
        </h1>
        <br />
        <br />
        {randomCommands.length == 0 ? (
          <>
            <div style={{ height: "210px" }}></div>
          </>
        ) : (
          <>
            {randomCommands.map((command: any, i: number) => (
              <div key={i} style={{ height: "70px" }}>
                {command.text}
                <br />
              </div>
            ))}
          </>
        )}

        {commandList.length <= 4 ? (
          <>
            <Button
              variant="outlined"
              disabled={true}
              style={{ width: "80%", fontSize: 20 }}
            >
              <br />
              読み込み中
              <br />
              <br />
            </Button>
            {/* <Button
              variant="outlined"
              disabled={false}
              style={{ width: "80%", fontSize: 20 }}
              onClick={() => {
                navigate("/Ekisentaku");
              }}
            >
              <br />
              戻る
              <br />
              <br />
            </Button> */}
          </>
        ) : (
          <>
            <Button
              variant="outlined"
              onClick={startRoulette}
              disabled={buttonDisabled}
              style={{ width: "80%", fontSize: 20 }}
            >
              <br />
              {start ? "ストップ" : "スタート"}
              <br />
              <br />
            </Button>
          </>
        )}
      </div>
    </>
  );
};

export default Shizigatya;
