import { Dispatch, SetStateAction, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Datas, Route } from "../../../common/types";
import { useParams } from "react-router-dom";
import { Button } from "@mui/material";

import { getDatas } from "../../../common/firebaseHelper/FirebaseTransactionHelper";

type Props = {
  datas: Datas;
  setDatas: Dispatch<SetStateAction<Datas>>;
  routeList: Route[];
};
const centerStyle = {
  display: "flex",
  flexDirection: "column" as const,
  alignItems: "center",
  justifyContent: "center",
  height: "100vh", //画面の高さいっぱいに表示する場合
}
const Sanponaiyou: React.FC<Props> = ({ datas, setDatas, routeList }) => {
  const navigate = useNavigate();
  let { ekiid,id1 } = useParams();

    return (
      <div style={centerStyle}>
        {datas.moveList.map((item, index) => (
          <div key={index}>
            {item.num} : {item.text}
          </div>
        ))}
        <br />
        <Button variant="outlined"
            onClick={() => {
            navigate("/Eki/"+ ekiid);
            }}
        >
            駅の選択に戻る
        </Button>
      </div>
    );
};

export default Sanponaiyou;
