import {
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
  useCallback,
  memo,
  Fragment,
} from "react";

import { get3commands } from "../../../common/firebaseHelper/FirebaseTransactionHelper";
import { useNavigate } from "react-router-dom";
import { Datas, Route } from "../../../common/types";
import { useParams } from "react-router-dom";
import { useQRCode } from "next-qrcode";

import { otherApp } from "../../../common/firebaseHelper/firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import { Timestamp } from "firebase/firestore"; // FirestoreのTimestamp型をインポート

import {
  Container,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  TextField,
  Button,
} from "@mui/material";

import { createTheme, ThemeProvider } from "@mui/material/styles";

import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import {
  getEvent,
  getEventBBSs,
  addEventBBS,
  getEventParticipants,
} from "../../../common/firebaseHelper/FirebaseTransactionHelperEvent";

import { getListOfUsersPublic } from "../../../common/firebaseHelper/FirebaseTransactionHelperUser";

import moment from "moment";

type Props = {
  datas: Datas;
  setDatas: Dispatch<SetStateAction<Datas>>;
  routeList: Route[];
};

const centerStyle = {
  display: "flex",
  flexDirection: "column" as const,
  alignItems: "center",
  justifyContent: "center",
  minHeight: "100vh", //画面の高さいっぱいに表示する場合
  width: "100%",
};

const Event: React.FC<Props> = ({ datas, setDatas, routeList }) => {
  let { eventid } = useParams();
  const [content, setContent] = useState<string>("");
  const [eventTitle, setEventTitle] = useState<string>("");
  const [eventDate, setEventDate] = useState<Date>(new Date(0));
  const [eventDateString, setEventDateString] = useState("");

  const [BBS, setBBS] = useState([]);
  const [userDatas, setUserDatas] = useState([]);
  const [changeCount, setChangeCount] = useState(0);
  const [eventParticipantsList, setEventParticipantsList] = useState([]);
  const navigate = useNavigate();

  const handleSubmit = () => {
    console.log("test");
  };

  const incrementChangeCount = () => {
    setChangeCount(changeCount + 1);
  };

  const parseAsMoment = (dateTimeStr: any) => {
    return moment.utc(dateTimeStr, "YYYY-MM-DDTHH:mm:00Z", "ja").utcOffset(9);
  };

  useEffect(() => {
    const f = async () => {
      console.log("ue_Event");

      //データを取得
      let doc = await getEvent(eventid);

      //イベントタイトルを設定
      setEventTitle(doc.data().title);

      //イベントの日程を設定
      if (doc.data().eventDate) {
        setEventDate(doc.data().eventDate.toDate());
        const eventdatestring = parseAsMoment(doc.data().eventDate.toDate())
          .format("YYYY/MM/DD HH:mm")
          .toString();
        setEventDateString(eventdatestring);
      } else {
        setEventDateString("イベントは中止になりました");
      }

      //イベント参加者一覧取得と、設定
      checkParticipants();

      //掲示板を確認
      const querySnapshot = await getEventBBSs(eventid);
      let lists: any = [];
      let userlists: any = new Set();
      querySnapshot.forEach((doc: any) => {
        // console.log(doc.id);
        lists.push({
          id: doc.data().id,
          content: doc.data().content,
          uid: doc.data().uid,
        });
        userlists.add(doc.data().uid); // UIDをSetに追加
      });
      userlists = Array.from(userlists); // Setを配列に変換

      let userPublicDataMap: any = {};

      // console.log(userlists);

      if (userlists.length > 0) {
        const querySnapshot2 = await getListOfUsersPublic(userlists);
        // querySnapshot2 をマップ形式に変換
        querySnapshot2.forEach((doc: any) => {
          userPublicDataMap[doc.id] = {
            name: doc.data().name,
            imageurl: doc.data().imageurl,
          };
        });

        setUserDatas(Object.values(userPublicDataMap));

        // lists を userPublicDataMap に基づいて展開し、name と imageurl を追加
        let lists3: any = lists.map((listItem: any) => {
          const userPublicData = userPublicDataMap[listItem.uid] || {};
          return {
            ...listItem,
            name: userPublicData.name || "Unknown",
            imageurl: userPublicData.imageurl || "default_image_url",
          };
        });
        setBBS(lists3);
      }
    };
    f();
  }, [changeCount]);

  const PostBBS = async () => {
    if (content) {
      const auth = getAuth(otherApp);
      const unsubscribe = onAuthStateChanged(auth, async (user: any) => {
        if (user) {
          await addEventBBS(eventid, { content: content, uid: user.uid });
          incrementChangeCount();
          setContent("");
          // ここで一度だけ処理を行い、リスナーを解除
          unsubscribe();
        } else {
          console.log("ログインしてください");
          unsubscribe();
        }
      });
    } else {
      console.log("何も記入されていません");
    }
  };

  const checkParticipants = async () => {
    console.log("move");
    const querySnapshot3 = await getEventParticipants(eventid);
    let lists3: any = [];
    querySnapshot3.forEach((doc: any) => {
      lists3.push({
        uid: doc.data().uid,
        name: doc.data().name,
      });
    });

    // console.log(lists3);

    // lists3が空かどうかを判定
    if (lists3.length > 0) {
      setEventParticipantsList(lists3);
    } else {
      console.log("参加者リストが空です。");
      // 必要に応じて他の処理をここに追加できます
    }
  };

  return (
    <div style={centerStyle}>
      <Container maxWidth="md" style={{ marginTop: "20px" }}>
        <Paper elevation={3} style={{ padding: "20px" }}>
          <Typography variant="h5" component="h2" gutterBottom>
            {/* イベントID: {eventid} */}
            {eventTitle}
          </Typography>
          <br />
          日程：{eventDateString}
          <br />
          <br />
          <u>🔳 イベント参加者</u>
          <br />
          {eventParticipantsList.map((item: any, index) => (
            <>{item.name}:</>
          ))}
          <br />
          <br />
          <u>🔳 参加者チャット</u>
          <br />
          <List>
            {BBS.map((bbsdata: any) => (
              <Fragment key={"data.id"}>
                {bbsdata.name} :{bbsdata.content}
                <Divider variant="inset" component="li" />
              </Fragment>
            ))}
          </List>
          <TextField
            label="投稿内容"
            fullWidth
            margin="normal"
            value={content}
            onChange={(e) => setContent(e.target.value)}
            required
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            style={{ marginTop: "10px" }}
            onClick={() => {
              PostBBS();
            }}
          >
            投稿する
          </Button>
        </Paper>
        <br />
      </Container>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => {
          navigate("/Book");
        }}
        style={{ fontSize: 20, width: "80%" }}
      >
        戻る
      </Button>
    </div>
  );
};

export default Event;
