import { Button } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

type Props = {
  isLogin: any;
  eventPlanList: any;
};

const MenuPlan: React.VFC<Props> = ({ isLogin, eventPlanList }: Props) => {
  const navigate = useNavigate();
  return (
    <div>
      <u>🔳 主催予定の一覧</u>

      {isLogin ? (
        <>
          <table>
            <tbody>
              {eventPlanList.length === 0 ? (
                <tr>
                  <td>あなたが主催のイベントはありません</td>
                </tr>
              ) : (
                <>
                  {eventPlanList.map((item: any, index: any) => (
                    <tr>
                      <td>{item.title}</td>

                      <td>
                        <Button
                          style={{
                            display: "inline-block",
                            padding: "10px 10px",
                            fontSize: "18px",
                            color: "#fff",
                            backgroundColor: "#3498db",
                            textDecoration: "none",
                            borderRadius: "5px",
                            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                            transition: "background-color 0.3s ease",
                          }}
                          onClick={() => {
                            navigate("/EditEvent/" + item.id);
                          }}
                        >
                          編集
                        </Button>
                      </td>
                    </tr>
                  ))}
                </>
              )}
              <tr>
                <Button
                  style={{
                    display: "inline-block",
                    padding: "10px 10px",
                    fontSize: "18px",
                    color: "#fff",
                    backgroundColor: "#3498db",
                    textDecoration: "none",
                    borderRadius: "5px",
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                    transition: "background-color 0.3s ease",
                  }}
                  onClick={() => {
                    navigate("/createEvent/");
                  }}
                >
                  作成
                </Button>
              </tr>
            </tbody>
          </table>
        </>
      ) : (
        <>
          <br />
          まずは
          <Button
            style={{
              display: "inline-block",
              padding: "10px 10px",
              fontSize: "18px",
              color: "#fff",
              backgroundColor: "#CC9900",
              textDecoration: "none",
              borderRadius: "5px",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              transition: "background-color 0.3s ease",
            }}
            onClick={() => {
              navigate("/Signin/");
            }}
          >
            ログイン
          </Button>
          しましょう
        </>
      )}
    </div>
  );
};

export default MenuPlan;
