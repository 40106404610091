import { otherApp } from "./firebase";
import {
  doc,
  collection,
  getFirestore,
  runTransaction,
  query,
  getDocs,
  getDoc,
  orderBy,
  limit,
  addDoc,
  setDoc,
  deleteDoc,
  updateDoc,
  documentId,
  where,
} from "firebase/firestore";


export async function setUserPrivateBook(
  userId: any,
  eventId: any,
  data: any
): Promise<any> {
  const db = getFirestore(otherApp);
  let q = doc(db, "service", "boukenguild", "private", userId, "book", eventId);
  const docRef = await setDoc(q, data);
  return docRef;
}

export async function updateUserPrivateBook(
  userId: any,
  eventId: any,
  data: any
): Promise<any> {
  const db = getFirestore(otherApp);
  let q = doc(db, "service", "boukenguild", "private", userId, "book", eventId);
  const docRef = await updateDoc(q, data);
  return docRef;
}

export async function getUserPrivateBook(userId: any): Promise<any> {
  const db = getFirestore(otherApp);
  let q = query(
    collection(db, "service", "boukenguild", "private", userId, "book"),
    limit(5)
  );
  const querySnapshot = await getDocs(q);
  return querySnapshot;
}

export async function deleteUserPrivateBook(
  userId: any,
  eventId: any
): Promise<any> {
  const db = getFirestore(otherApp);
  // ドキュメントのパスを構築
  const docRef = doc(
    db,
    "service",
    "boukenguild",
    "private",
    userId,
    "book",
    eventId
  );
  // ドキュメントを削除
  await deleteDoc(docRef);
  return true;
}


export async function getUserPrivatePlan(
  userId: any,
): Promise<any> {
  const db = getFirestore(otherApp);
  let q = query(
    collection(db, "service", "boukenguild", "private", userId, "plan"),
    limit(5)
  );
  const querySnapshot = await getDocs(q);
  return querySnapshot;
}

export async function setUserPrivatePlan(
  userId: any,
  eventId: any,
  data: any
): Promise<any> {
  const db = getFirestore(otherApp);
  let q = doc(db, "service", "boukenguild", "private", userId, "plan", eventId);
  const docRef = await setDoc(q, data);
  return docRef;
}