import React, { useRef, useState } from "react";
import { Virtual, Navigation, Pagination } from "swiper/modules";
import { SwiperSlide, Swiper } from "swiper/react";
import { Swiper as SwiperType } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import "./styles.css";
import { useNavigate } from "react-router-dom";

import Hara1 from "./pic/Event202312_3.jpeg";
import Kou1 from "./pic/Event20230826_20.jpeg";
import Ebisu1 from "./pic/Event20240303_4.png";
import Tokyo1 from "./pic/Event20240616_2.png";

type Props = {
  eventPastList: any;
};

const PastEvents: React.VFC<Props> = ({ eventPastList }: Props) => {
  const [swiperRef, setSwiperRef] = useState<SwiperType | null>();
  const appendNumber = useRef(10);
  const prependNumber = useRef(1);

  const [illustrations, setIllustrations] = useState([
    {
      id: 4,
      imageUrl: Tokyo1,
      alt: "Illustration 4",
      url: "/Event/z62EpzqqrnNZ1kiGemcU",
      text: "東京散歩",
    },
    {
      id: 3,
      imageUrl: Ebisu1,
      alt: "Illustration 3",
      url: "/Event/wtWc0mvukl0KlV7wlJIF",
      text: "恵比寿散歩",
    },
    {
      id: 2,
      imageUrl: Hara1,
      alt: "Illustration 2",
      url: "/Event/4cEAF9wLv0xRWDfwcBrE",
      text: "原宿散歩",
    },
    {
      id: 1,
      imageUrl: Kou1,
      alt: "Illustration 1",
      url: "/Event/peAHnWBg1EH8g1hEtq4w",
      text: "交流会",
    },
  ]);

  const navigate = useNavigate();

  return (
    <>
      <Swiper
        modules={[Virtual, Navigation, Pagination]}
        onSwiper={setSwiperRef}
        slidesPerView={3}
        centeredSlides={true}
        spaceBetween={30}
        pagination={{ type: "fraction" }}
        navigation={true}
        virtual
        initialSlide={1} // これが初期スライドを2番目に設定する部分です
      >
        {illustrations.map((illustration, index) => (
          <SwiperSlide key={illustration.id} virtualIndex={index}>
            <div
              onClick={() => {
                navigate(illustration.url);
              }}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <table style={{ borderCollapse: "collapse" }}>
                <tbody>
                  <tr>
                    <td style={{ textAlign: "center" }}>
                      <div>{illustration.text}</div>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={illustration.imageUrl}
                        alt={illustration.alt}
                        style={{ width: "75%", height: "75%", border: "none" }}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default PastEvents;
