import { useRef } from "react";
import { Box, CircularProgress, Typography, Avatar } from "@mui/material";
import React from "react";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { firebaseApp } from "../firebaseHelper/firebase";

import { updateCommand } from "../firebaseHelper/FirebaseTransactionHelper";

type Props = {
  eki_id: string;
  shizi_id: string;
  imageurl: string;
  setimageurl: any;
  openingtime: any;
  closingtime: any;
};

const ImageSetting: React.FC<Props> = ({
  eki_id,
  shizi_id,
  imageurl,
  setimageurl,
  openingtime,
  closingtime,
}) => {
  const inputRef: any = useRef(null);

  const value = 29;
  const chartcolors = { 0: "#757ce8", 1: "#ff7961" };
  const fileUpload = () => {
    inputRef.current.click();
  };
  //uploadできる画像のバイト数のリミット
  const limitUpdateByte = 300000;

  async function onSubmit(event: any) {
    event.preventDefault();
    if (typeof event.target.files[0] === "undefined") {
      console.log("ファイルが選択されていません");
      return;
    }
    let avatarImage = event.target.files[0];
    //console.log(avatarImage);
    //画像を圧縮
    // new Compressor(avatarImage, {
    //   quality: 0.6,
    // success(result) {
    // const image = result;
    const image = avatarImage;
    if (image.size < limitUpdateByte) {
      //imgnameの名前づけ(uid+imgname)
      let imgname = Date.now();
      const storage = getStorage(firebaseApp);
      //console.log(props.lonpaid);
      const storageRef = ref(
        storage,
        // "EventImage/" + imgname
        "YamanoteRoute/Command" + imgname
      ); //どのフォルダの配下に入れるかを設定
      const uploadTask = uploadBytesResumable(storageRef, image); //ファイル名

      //ファイルをアップロードする
      uploadTask.on("state_changed", () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          //console.log(eki_id, shizi_id);
          const f = async () => {
            await updateCommand(eki_id, shizi_id, {
              imageURL: String(downloadURL),
              openingtime: openingtime,
              closingtime: closingtime,
            },);
            setimageurl(downloadURL);
            //console.log(downloadURL);
          };

          f();
        });
      });
    } else {
      alert("容量が大きすぎます。やり直してください");
    }
    // },
    // error(err) {
    //   console.log(err);
    //   return;
    // },
    // });
  }
  return (
    <>
      <img
        onClick={() => {
          fileUpload();
        }}
        src={imageurl}
        style={{
          height: 80,
          width: 80,
        }}
      />
      <input
        hidden
        ref={inputRef}
        type="file"
        accept="image/*"
        onChange={onSubmit}
      />
    </>
  );
};

export default ImageSetting;
