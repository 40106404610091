import { useEffect, useState } from "react";

import {
  Button,
  Grid,
  TextField,
  Typography,
  Box,
  Divider,
  Link,
} from "@mui/material";
import { otherApp } from "../../../common/firebaseHelper/firebase";

import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Facebook from "@mui/icons-material/Facebook";
// import Twitter from "@mui/icons-material/Twitter";
import XIcon from "@mui/icons-material/X";
// import { useStyles } from "../Styles";
import { useNavigate } from "react-router-dom";
import {
  getAuth,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  FacebookAuthProvider,
  TwitterAuthProvider,
  GoogleAuthProvider,
  signInWithRedirect,
  onAuthStateChanged,
} from "firebase/auth";
import * as yup from "yup";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  VALIDATION_EMAIL,
  VALIDATION_ERR_REQUIRED,
  VALIDAtION_INVALID_CHARACTER,
  VALIDATION_LONG_MAX_LENGTH,
  VALIDATION_PASSWORD_MAX_LENGTH,
  VALIDATION_PASSWORD_MIN_LENGTH,
} from "../../../common/validation";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Google } from "@mui/icons-material";

const schema = yup.object({
  email: yup.string().email(VALIDATION_EMAIL).required(VALIDATION_ERR_REQUIRED),
  password: yup
    .string()
    .required(VALIDATION_ERR_REQUIRED)
    .min(8, VALIDATION_PASSWORD_MIN_LENGTH)
    .max(16, VALIDATION_PASSWORD_MAX_LENGTH),
});

const Signup = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errormessage, setErrorMessage] = useState("");
  const [lodingmodalopen, setLodingmodalopen] = useState(true);
  // const classes = useStyles();
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const customTheme = createTheme({
    components: {
      MuiButton: {
        styleOverrides: {
          outlined: {
            backgroundColor: "#dddddd",
          },
        },
      },
    },
    palette: {
      primary: {
        main: "#F7C147",
      },
    },
  });

  useEffect(() => {
    console.log("uE_Signup");
    onAuthStateChanged(getAuth(otherApp), async (user) => {
      if (user) {
        window.scrollTo(0, 0);
        console.log('ログインしている');
        navigate("/Book");
      } else {
        setLodingmodalopen(false);
      }
    });
  }, []);

  const handleSubmittwitter = () => {
    var provider = new TwitterAuthProvider();
    const auth = getAuth(otherApp);
    //ログインページにリダイレクトしてログイン
    signInWithRedirect(auth, provider);
    afterSignUp();
  };
  const handleSubmitfacebook = () => {
    // console.log("facebook")
    //Facebook プロバイダ オブジェクトのインスタンスを作成
    const provider = new FacebookAuthProvider();
    const auth = getAuth(otherApp);
    //ログインページにリダイレクトしてログイン
    signInWithRedirect(auth, provider);
    afterSignUp();
  };

  const handleSubmitgoogle = () => {
    var provider = new GoogleAuthProvider();
    const auth = getAuth(otherApp);
    //ログインページにリダイレクトしてログイン
    signInWithRedirect(auth, provider);
    afterSignUp();
  };

  const afterSignUp = () => {

    window.scrollTo(0, 0);
    navigate("/Book");
  };

  const handleSignup = () => {
    createUserWithEmailAndPassword(getAuth(otherApp), email.value, password.value)
      .then((userCredential) => {
        try {
          const actionCodeSettings = {
            url: `https://lonpa.net/Signin`,
          };
          sendEmailVerification(getAuth(otherApp).currentUser, actionCodeSettings).then(
            () => {
              alert(
                "検証用のメールを送信しました。有効化して，投票を開始しましょう"
              );
            }
          );
        } catch (e) {
          console.log(e);
          console.log("有効化メールの送信に失敗しました");
        }
        props.update();
        window.scrollTo(0, 0);
        // navigate("/Loadmap");
      })
      .catch((error) => {
        var errorMessage = error.message;

        if (errorMessage === "Firebase: Error (auth/email-already-in-use).") {
          errorMessage = "既にそのアカウントは存在します";

          alert(
            "既にそのアカウントは存在します．まずはログインしてみましょう．"
          );
          window.scrollTo(0, 0);
          navigate("/Signin");
        }
        setErrorMessage(errorMessage);
      });
  };
  async function handleSignin() {
    window.scrollTo(0, 0);
    navigate("/Signin");
  }

  return (
    <div
      align="center"
      style={{
        maxWidth: "500px",
        margin: "0 auto",
      }}
    >
      <div style={{ height: "5vh" }}></div>
      <Typography align="left" fontWeight="bold" variant="h6">
        {/* <LockOutlinedIcon style={{ margin: "9px" }} /> */}
        メールでアカウント作成
      </Typography>

      <Controller
        control={control}
        name="email"
        render={({ field: { onChange } }) => (
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            onChange={(e) => {
              onChange(e);
              setEmail({ value: e.target.value });
            }}
          />
        )}
      />
      <Typography>{errors.email?.message}</Typography>
      <Controller
        control={control}
        name="password"
        render={({ field: { onChange } }) => (
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={(e) => {
              onChange(e);
              setPassword({ value: e.target.value });
            }}
          />
        )}
      />
      <Typography>{errors.password?.message}</Typography>
      <div style={{ color: "red", fontSize: 15 }}>{errormessage}</div>
      <ThemeProvider theme={customTheme}>
        <Button
          fullWidth
          variant="contained"
          // className={classes.submit}
          onClick={handleSubmit(handleSignup)}
        >
          メール認証に進む
        </Button>
      </ThemeProvider>
      <div style={{ height: "1vh" }}></div>
      <Typography display="block">
        すでにアカウントをお持ちの方は
        <Link href="#" color="#6495ed" onClick={handleSignin}>
          こちら
        </Link>
      </Typography>
      <div style={{ height: "4vh" }}></div>
      <Divider />

      <Box
        sx={{
          pb: 1,
          pt: 3,
        }}
      >
        <Typography align="left" fontWeight="bold" variant="h6">
          {/* <LockOutlinedIcon style={{ margin: "9px" }} /> */}
          アカウント連携でアカウント作成
        </Typography>
      </Box>

      <Grid container spacing={3}>
        {/* <Grid item xs={12} md={6}>
          <ThemeProvider theme={customTheme}>
            <Button
              fullWidth
              startIcon={<Facebook />}
              onClick={handleSubmitfacebook}
              variant="contained"
            >
              Facebook
            </Button>
          </ThemeProvider>
        </Grid> */}
        <Grid item xs={12} md={6}>
          <ThemeProvider theme={customTheme}>
            <Button
              fullWidth
              startIcon={<XIcon />}
              onClick={handleSubmittwitter}
              variant="contained"
            >
              X
            </Button>
          </ThemeProvider>
        </Grid>
        <Grid item xs={12} md={6}>
          <ThemeProvider theme={customTheme}>
            <Button
              fullWidth
              startIcon={<Google />}
              onClick={handleSubmitgoogle}
              variant="contained"
            >
              Google
            </Button>
          </ThemeProvider>
        </Grid>
      </Grid>

      <div style={{ height: "5vh" }}></div>
    </div>
  );
};

export default Signup;
