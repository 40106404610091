import { Button } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PastEvents from "./PastEvents";

type Props = {
  isLogin: any;
  eventPastList: any;
  eventFutureList: any;
  cancelUser: any;
  bookUser: any;
  errorText: any;
};

const MenuEvent: React.VFC<Props> = ({
  isLogin,
  eventPastList,
  eventFutureList,
  cancelUser,
  bookUser,
  errorText,
}: Props) => {
  const navigate = useNavigate();
  return (
    <div>
      <u>🔳 開催予定の一覧</u>
      <br />
      <table>
        <tbody>
          {eventFutureList.map((item: any, index: any) => (
            <tr>
              <td>{item.title}</td>

              {isLogin ? (
                <>
                  {item.disabled ? (
                    <>
                      <td>
                        <Button
                          style={{
                            display: "inline-block",
                            padding: "10px 10px",
                            fontSize: "18px",
                            color: "#fff",
                            backgroundColor: "#3498db",
                            textDecoration: "none",
                            borderRadius: "5px",
                            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                            transition: "background-color 0.3s ease",
                          }}
                          onClick={() => {
                            cancelUser(item.id, item.title);
                          }}
                        >
                          不参加
                        </Button>
                      </td>
                      <td>
                        <Button
                          style={{
                            display: "inline-block",
                            padding: "10px 10px",
                            fontSize: "18px",
                            color: "#fff",
                            backgroundColor: "#3498db",
                            textDecoration: "none",
                            borderRadius: "5px",
                            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                            transition: "background-color 0.3s ease",
                          }}
                          onClick={() => {
                            navigate("/Event/" + item.id);
                          }}
                        >
                          詳細
                        </Button>
                      </td>
                    </>
                  ) : (
                    <>
                      <td>
                        <Button
                          style={{
                            display: "inline-block",
                            padding: "10px 10px",
                            fontSize: "18px",
                            color: "#fff",
                            backgroundColor: "#e74c3c",
                            textDecoration: "none",
                            borderRadius: "5px",
                            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                            transition: "background-color 0.3s ease",
                          }}
                          onClick={() => {
                            bookUser(item.id, item.title);
                          }}
                        >
                          参加
                        </Button>
                        {errorText}
                      </td>
                    </>
                  )}
                </>
              ) : (
                <>
                  <td>
                    <Button
                      style={{
                        display: "inline-block",
                        padding: "10px 10px",
                        fontSize: "18px",
                        color: "#fff",
                        backgroundColor: "#CC9900",
                        textDecoration: "none",
                        borderRadius: "5px",
                        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                        transition: "background-color 0.3s ease",
                      }}
                      onClick={() => {
                        bookUser(item.id, item.title);
                      }}
                    >
                      予約のためにログイン
                    </Button>
                  </td>
                </>
              )}

              {/* <td>
                          <Button
                            onClick={() => {
                              checkParticipants(item.id);
                              setIsMemberOpen(true);
                            }}
                          >
                            参加者を見る
                          </Button>
                        </td> */}
            </tr>
          ))}
        </tbody>
      </table>
      <br />
      <u>🔳 過去イベント</u>
      <br />
      <br />
      <PastEvents eventPastList={eventPastList} />
    </div>
  );
};

export default MenuEvent;
